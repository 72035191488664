import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Accordion from "components/Accordion/Accordion.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        <div className={classes.features3}>
          <GridContainer>
            <GridItem xs={6}>
              <Accordion
                activeColor="danger"
                collapses={[
                  {
                    title: "TRACE is an Element Tracking System (ETS)",
                    content: (
                      <h4>
                        Are you still emailing spreadsheets to manage mountains
                        of details. TRACE is an ETS that makes all the elements
                        of your creative projects available to you and your
                        entire team at any time on any device, including every
                        element of your projects, process, and people.
                        <p />
                        A true Element Tracking System manages the details of
                        all workflows so you can live in an environment of
                        productive creativity. Thought Development’s TRACE is an
                        ETS that accomplishes this with you.
                        <p />
                        Our product TRACEs everything! Literally! All the tasks,
                        all the data, all the steps, who did it, when they did
                        it, when you need it, all in a gratifying, fun to use,
                        eye pleasing, and easily accessible way.
                        <p />
                        TRACE your elements.
                        <div style={{ textAlign: "end" }}>
                          <Button color="danger" size="md" href="/">
                            <i className="fas fa-play" />
                            Call to Action
                          </Button>
                        </div>
                      </h4>
                    ),
                  },
                  {
                    title:
                      "TRACE is a high ROI team environment for managing creative work",
                    content: (
                      <h4>
                        The use of an ETS such as TRACE provides a high return
                        on investment (ROI) for your team and your tribe—in both
                        time and money.
                        <p />
                        In other industries, software tools such as CRMs
                        (Customer Relations Management) and ERPs (Enterprise
                        Resource Planning) have evolved enormously as powerful
                        tools in the last few years.
                        <p />
                        In the area of creative work, the Element Tracking
                        System (ETS) is the preferred software tool. TRACE is
                        the evolution in creative workflow management.
                        <p />
                        TRACE administrates for you. So you don’t have to. As
                        each member of the team does their part, each element in
                        the process is tracked and is reportable. This provides
                        a high ROI for everyone’s time, and your money.
                        <p />
                        Stop wasting time with pointless communication,
                        frustrating quests for process status, or fruitless
                        searches for assets. Everything you need is now in front
                        of you.
                        <p />
                        TRACE: your work, working for you.
                        <div style={{ textAlign: "end" }}>
                          <Button color="danger" size="md" href="/">
                            <i className="fas fa-play" />
                            Call to Action
                          </Button>
                        </div>
                      </h4>
                    ),
                  },
                  {
                    title:
                      "TRACE lets you put your finger on any detail in any project in fifteen seconds or less",
                    content: (
                      <h4>
                        The success or failure of an element tracking system
                        (ETS) can be determined by this metric alone. Can you
                        find any element of your creative work in 15 seconds?
                        The correct graphic, the 'approved' script, design or
                        delivery spec notes? How about from 5 months ago, or
                        even 5 years ago?
                        <p />
                        TRACE the elements your project. Put your finger on the
                        approved script, the correct graphic, the necessary
                        music, and the selected fonts.
                        <p />
                        TRACE the elements your process. Eliminate the question:
                        “What’s next in the workflow?” TRACE your steps. Smart,
                        visual key indicators provide a roadmap for element
                        progress throughout your project. Everyone on the team
                        knows exactly what’s coming next.
                        <p />
                        TRACE the elements your people. Who is doing what,
                        where, when, and how. Are your creatives overloaded?
                        Let’s load balance.
                        <p />
                        TRACE: your data, serving you.
                        <div style={{ textAlign: "end" }}>
                          <Button color="danger" size="md" href="/">
                            <i className="fas fa-play" />
                            Call to Action
                          </Button>
                        </div>
                      </h4>
                    ),
                  },
                ]}
              />
            </GridItem>
            <GridItem xs={6}>
              <Accordion
                activeColor="danger"
                collapses={[
                  {
                    title:
                      "TRACE is fully mobile and securely accessible, with a dead sexy intuitive UI and satisfying UX, available anywhere on any device",
                    content: (
                      <h4>
                        You’re sitting in your favorite café, while being your
                        most productive self with your team. Do any task you
                        need to do, anywhere, anytime. You can do that with
                        TRACE.
                        <p />
                        You need to know that your data is secure and private,
                        shielded from prying eyes. Your content is only
                        accessible to you. TRACE follows highest security
                        standards using premier tools such as SHA256 for private
                        mobile connectivity.
                        <p />
                        Can you accomplish your most important tasks in your
                        software without any help? In your creative industry,
                        you need quick onboarding and minimal technical barriers
                        to entry. World-class software requires little training
                        to use, and TRACE is no exception.
                        <p />
                        The amount of time you spend with your software tools
                        require that they should be comfortable, pleasing to the
                        eye, and elegantly usable. Why suffer with ugly colors
                        and inartful design? The TRACE user experience is
                        familiar, satisfying, and non-stressful.
                        <p />
                        If your element tracking system doesn’t reflect all
                        these qualities, it’s a fail.
                        <div style={{ textAlign: "end" }}>
                          <Button color="danger" size="md" href="/">
                            <i className="fas fa-play" />
                            Call to Action
                          </Button>
                        </div>
                      </h4>
                    ),
                  },
                  {
                    title:
                      "TRACE plugs and plays well with others, allowing you ultimate flexibility and expanded capability",
                    content: (
                      <h4>
                        Modularity matters. No one can be one size fits all.
                        Third party capabilities add power to your workflow. We
                        understand that, and we embrace it.
                        <p />
                        Thought Development devs are integration ninjas.
                        Document deliverables for your existing production
                        chain. Export video metadata to (systems) Establish a
                        live connection with music cue libraries.
                        <p />
                        You haven’t had tools like this before. Output or input
                        from media asset managers, data asset managers, and
                        traffic systems. Integrate data sets to bring powerful
                        decision-making results. Your data is your data, and
                        with TRACE you can use it however you want.
                        <p />
                        TRACE gives you timely information, when you need it.
                        <div style={{ textAlign: "end" }}>
                          <Button color="danger" size="md" href="/">
                            <i className="fas fa-play" />
                            Call to Action
                          </Button>
                        </div>
                      </h4>
                    ),
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
